import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import {
    deleteVersion,
    getVersions
} from '../../redux/actions/versionsActions';
import { Button, List, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const VersionList = () => {
    const versions = useSelector((state) => state.versions);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVersions());
    }, [dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteVersion(id));
    };

    return <>
        <List
            itemLayout="horizontal"
            dataSource={versions.data}
            pagination={{
                pageSize: 5,
            }}
            renderItem={(item) => (
                <List.Item key={item._id}>
                    <List.Item.Meta
                        description={<div>
                            <h1>{item.version}</h1>
                            <Popconfirm title={`Действительно удалить ${item.version}?`}
                                        onConfirm={() => handleDelete(item._id)}>
                                <Button icon={<DeleteOutlined />} danger>Удалить</Button>
                            </Popconfirm>
                        </div>}
                    />
                </List.Item>
            )}
        />
    </>
};
