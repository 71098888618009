import JSZip from 'jszip';

const zip = JSZip();
const createZipBlobFromFiles = function(files) {
    return new Promise((resolve, reject) => {
        for (let file = 0; file < files.length; file++) {
            try {
                zip.file(files[file].name, files[file]);
            } catch (err) {
                reject(err);
                return;
            }
        }
        zip.generateAsync({type: "blob"}).then(content => {
            resolve(content);
        }).catch(err => reject(err));
    });
}

export {
    createZipBlobFromFiles
};
