import React from 'react'
import { Row, Col } from "antd";
import ReactPlayer from 'react-player'
const PresetView = ({ name, image, video, audio }) => {
    return <>
        <Row justify='center'>
            {image &&
                <img width={149} height={149} src={image} alt={name} />}
        </Row>
        <br />
        <Row justify='center'>
            {video &&
                <ReactPlayer
                    className='react-player'
                    url={video}
                    width={320}
                    height={240}
                    controls
                />}
        </Row>
        <br />
        <Row justify='center' >
            <Col xl={20}>
                <ReactPlayer
                    className='react-player'
                    url={audio}
                    width='100%'
                    height='150%'
                    controls
                />
            </Col>
        </Row>
    </>
}


export default PresetView;
