import { call, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
    GET_PRESETS_SUCCESS,
    GET_PRESETS_REQUESTED,
    ADD_PRESETS_REQUESTED,
    ADD_PRESETS_SUCCESS,
    DELETE_PRESETS_REQUESTED,
    DELETE_PRESETS_SUCCESS,
    PRESETS_FAILED,
    DELETE_PRESETS_VERSION_SUCCESS,
    DELETE_PRESETS_VERSION_REQUESTED,
    SORT_PRESETS_REQUESTED,
    SORT_PRESETS_SUCCESS
} from '../actions/actionTypes';
import { failedHttpRequest } from '../actions/commonActions';
import { presetSetSortOrder, presetVersionDelete } from '../../services/backend';
import { arrayMove } from 'react-sortable-hoc';
import { sortBy } from 'lodash';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const getPresets = async (version) => await axios.get(`${backendURL}/preset/${version}`)
    .then((result) => result.data)
    .then(x => sortBy(x, ['sortOrder']))
    .catch((error) => {
        throw error;
    });

const postPreset = async (data) => await axios.post(`${backendURL}/preset/create`, data);
const deletePreset = async id => await axios.delete(`${backendURL}/preset/${id}`)


function* fetchPresets(action) {
    try {
        const presets = yield call(() => getPresets(action.payload));
        yield put({ type: GET_PRESETS_SUCCESS, payload: presets });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* fetchCreatePreset(action) {
    try {
        const preset = yield call(() => postPreset(action.payload));
        yield put({ type: ADD_PRESETS_SUCCESS, payload: preset });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* callDeletePreset(action) {
    try {
        yield call(() => deletePreset(action.payload._id));
        yield put({ type: DELETE_PRESETS_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* callDeletePresetVersion(action) {
    try {
        yield call(() => presetVersionDelete(action.payload.id, action.payload.version));
        yield put({ type: DELETE_PRESETS_VERSION_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* sortPresets(action) {
    try {
        const presets = yield select(state => state.presets.data);
        console.log(presets, action.payload);
        const payload = arrayMove(presets, action.payload.oldIndex, action.payload.newIndex);
        console.log(payload);
        for (let i = 0; i < payload.length; i++) {
            payload[i].sortOrder = i + 1;
        }
        console.log(payload);
        yield call(() => presetSetSortOrder(payload.map(x => ({id: x._id, sortOrder: x.sortOrder}))));
        yield put({ type: SORT_PRESETS_SUCCESS, payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* presetsSortSaga() {
    yield takeEvery(SORT_PRESETS_REQUESTED, sortPresets);
}

function* presetsSaga() {
    yield takeEvery(GET_PRESETS_REQUESTED, fetchPresets);
}

function* presetCreateSaga() {
    yield takeEvery(ADD_PRESETS_REQUESTED, fetchCreatePreset);
}

function* presetDeleteSaga() {
    yield takeEvery(DELETE_PRESETS_REQUESTED, callDeletePreset);
}

function* presetVersionDeleteSaga() {
    yield takeEvery(DELETE_PRESETS_VERSION_REQUESTED, callDeletePresetVersion);
}

export { presetsSaga, presetCreateSaga, presetDeleteSaga,
    presetVersionDeleteSaga,
    presetsSortSaga
};
