// presets
export const SORT_PRESETS_REQUESTED = 'SORT_PRESETS_REQUESTED';
export const SORT_PRESETS_SUCCESS = 'SORT_PRESETS_SUCCESS';
export const GET_PRESETS_REQUESTED = 'GET_PRESETS_REQUESTED';
export const GET_PRESETS_SUCCESS = 'GET_PRESETS_SUCCESS';
export const ADD_PRESETS_REQUESTED = 'ADD_PRESETS_REQUESTED';
export const ADD_PRESETS_SUCCESS = 'ADD_PRESETS_SUCCESS';
export const DELETE_PRESETS_VERSION_REQUESTED = 'DELETE_PRESETS_VERSION_REQUESTED';
export const DELETE_PRESETS_VERSION_SUCCESS = 'DELETE_PRESETS_VERSION_SUCCESS';
export const DELETE_PRESETS_REQUESTED = 'DELETE_PRESETS_REQUESTED';
export const DELETE_PRESETS_SUCCESS = 'DELETE_PRESETS_SUCCESS';
export const PRESETS_FAILED = 'PRESETS_FAILED';


// preset
export const GET_PRESET_REQUESTED = 'GET_PRESET_REQUESTED';
export const GET_PRESET_SUCCESS = 'GET_PRESET_SUCCESS';
export const ADD_PRESET_REQUESTED = 'ADD_PRESET_REQUESTED';
export const ADD_PRESET_SUCCESS = 'ADD_PRESET_SUCCESS';
export const UPDATE_PRESET_REQUESTED = 'UPDATE_PRESET_REQUESTED';
export const UPDATE_PRESET_SUCCESS = 'UPDATE_PRESET_SUCCESS';
export const COPY_PRESET_TO_TARGET_REQUESTED = 'COPY_PRESET_TO_TARGET_REQUESTED';
export const COPY_PRESET_TO_TARGET_SUCCESS = 'COPY_PRESET_TO_TARGET_SUCCESS';
export const COPY_PRESET_TO_ALL_REQUESTED = 'COPY_PRESET_TO_ALL_REQUESTED';
export const COPY_PRESET_TO_ALL_SUCCESS = 'COPY_PRESET_TO_ALL_SUCCESS';

// versions
export const GET_VERSIONS_REQUESTED = 'GET_VERSIONS_REQUESTED';
export const GET_VERSIONS_SUCCESS = 'GET_VERSIONS_SUCCESS';
export const ADD_VERSIONS_REQUESTED = 'ADD_VERSIONS_REQUESTED';
export const ADD_VERSIONS_SUCCESS = 'ADD_VERSIONS_SUCCESS';
export const DELETE_VERSIONS_REQUESTED = 'DELETE_VERSIONS_REQUESTED';
export const DELETE_VERSIONS_SUCCESS = 'DELETE_VERSIONS_SUCCESS';
export const VERSIONS_FAILED = 'VERSIONS_FAILED';

// upload
export const UPLOAD_INIT = 'UPLOAD_INIT';
export const UPLOAD_FILE_REQUESTED = 'UPLOAD_FILE_REQUESTED';
export const UPLOAD_FILE_QUEUED = 'UPLOAD_FILE_QUEUED';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';
