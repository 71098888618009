const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const backendUrl = path => {
  return `${REACT_APP_BACKEND_URL}${path}`
};

const frontendUrl = path => path;

const frontendPaths = {
    Root: '/',
    Login: '/login',
    Callback: '/callback',
    Preset: '/preset',
    Versions: '/versions',
    VersionsCreate: '/versions/create'
};

export {
  backendUrl,
  frontendUrl,
  frontendPaths
}
