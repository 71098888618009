import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import presetsReducer from './presetsReducer';
import activePresetReducer from './activePresetReducer';
import uploadReducer from './uploadReducer';
import { versionsReducer } from './versionsReducer';

const rootReducer = combineReducers({
    presets: presetsReducer,
    versions: versionsReducer,
    activePreset: activePresetReducer,
    upload: uploadReducer,
    form: formReducer,
});

export default rootReducer;
