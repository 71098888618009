const createLogEntry = (presetName, inputName, fileName, type, extra = {}) => ({
    presetName,
    inputName,
    fileName,
    type,
    createdAt: new Date(),
    ...extra
});

const createRequirement = (fileInputName, requiredFilesCount) => ({
   fileName: fileInputName,
   requiredFilesCount
});

const LOG_ENTRY_TYPES = {
    ARCHIVING: 'ARCHIVING',
    UPLOADING: 'UPLOADING',
    UPLOADED: 'UPLOADED',
    FAILED: 'FAILED'
}

const state = {
    initialState: () => ({
        requirements: [],
        log: [],
        requests: []
    }),

    createLogEntry,
    createRequirements: createRequirement,
    addUploadRequirement: (state, requirements) => {
        return {
            ...state,
            requirements: [
                ...state.requirements,
                ...requirements
            ]
        }
    },
    addUploadRequest: (state, uploadRequest) => {
        return {
            ...state,
            requests: [
                ...state.requests,
                uploadRequest
            ]
        }
    },
    addLogEntries: (state, logEntries) => {
        return {
            ...state,
            log: [
                ...state.log,
                ...logEntries
            ]
        }
    },
};

export {
    createLogEntry,
    createRequirement,
    LOG_ENTRY_TYPES
}

export default state;
