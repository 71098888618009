import {
    GET_PRESETS_REQUESTED,
    GET_PRESETS_SUCCESS,
    ADD_PRESETS_REQUESTED,
    ADD_PRESETS_SUCCESS,
    PRESETS_FAILED,
    DELETE_PRESETS_REQUESTED,
    DELETE_PRESETS_SUCCESS,
    DELETE_PRESETS_VERSION_REQUESTED,
    DELETE_PRESETS_VERSION_SUCCESS,
    SORT_PRESETS_REQUESTED,
    SORT_PRESETS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false,
    page: 1
};

export default function presetsReducer(state = initialState, action) {
    switch (action.type) {
        case SORT_PRESETS_REQUESTED:
            return { ...state, loading: true };
        case SORT_PRESETS_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case GET_PRESETS_REQUESTED:
            return { ...state, loading: true };
        case GET_PRESETS_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case ADD_PRESETS_REQUESTED:
        case DELETE_PRESETS_REQUESTED:
        case DELETE_PRESETS_VERSION_REQUESTED:
            return { ...state, loading: true };
        case DELETE_PRESETS_SUCCESS:
            return {
                ...state,
                success: true,
                data: state.data.filter(x => x._id !== action.payload._id),
            };
        case DELETE_PRESETS_VERSION_SUCCESS:
            return {
                ...state,
                success: true,
                data: state.data.filter(x => x._id !== action.payload.id),
            };
        case ADD_PRESETS_SUCCESS:
            return {
                ...state,
                success: true,
                data: [action.payload.data, ...state.data],

            };
        case PRESETS_FAILED:
            return {
                ...state,
                success: false,
                error: action.message,
                reference: action.reference
            };
        default:
            return state;
    }
}
