import {
    ADD_VERSIONS_REQUESTED,
    DELETE_VERSIONS_REQUESTED,
    GET_VERSIONS_REQUESTED
} from './actionTypes';

export const getVersions = () => {
    return {
        type: GET_VERSIONS_REQUESTED,
    };
};

export const createVersion = (data) => {
    return {
        type: ADD_VERSIONS_REQUESTED,
        payload: {
            version: data
        },
    };
};

export const deleteVersion = (id) => {
    return {
        type: DELETE_VERSIONS_REQUESTED,
        payload: id,
    };
};
