import { Col, Row } from 'antd';
import React from 'react';
import { CreateVersionForm } from '../../components/CreateVersionForm';
import { useDispatch } from 'react-redux';
import { createVersion } from '../../redux/actions/versionsActions';
import { frontendPaths } from '../../services/url';
import { useHistory } from 'react-router-dom';

export const CreateVersion = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = async values => {
        dispatch(createVersion(values.version));
        history.push(frontendPaths.Versions);
    };

    return <>
        <Row justify='center'>
            <h1>Создание версии</h1>
        </Row>
        <Row justify='center'>
            <Col xl={5} md={8}>
                <CreateVersionForm handleSubmit={handleSubmit} />
            </Col>
        </Row>
    </>
};
