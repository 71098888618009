// TODO: extract to some common package since it is used here and in apps-registrator
import {backendUrl} from './url';

const ID_TOKEN = 'id_token';
const EXPIRES_IN = 'expires_in';
const REFRESH_TOKEN = 'refresh_token';
const EMAIL = 'email';

const authUrl = path => {
  return backendUrl(`/auth${path}`);
};

const isAuthenticated = () => {
  return localStorage.getItem(ID_TOKEN) !== null;
};

const issueToken = async code => {
  const response = await fetch(
    authUrl('/google/token'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code
      })
    },
  );
  const body = await response.json();
  if (body.id_token && body.expires_in) {
    localStorage.setItem(ID_TOKEN, body.id_token);
    localStorage.setItem(EXPIRES_IN, Date.now() + (body.expires_in *1000));
    localStorage.setItem(REFRESH_TOKEN, body.refresh_token);
    return true;
  } else {
    return false;
  }
}

const refreshToken = async token => {
    const response = await fetch(
        authUrl('/google/refreshToken'),
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
            }),
    })
      const tokenData = await response.json();
      localStorage.setItem(ID_TOKEN, tokenData.id_token);
      localStorage.setItem(EXPIRES_IN, Date.now() + (tokenData.expires_in *1000));
      return tokenData.id_token;
}

const authorizationHeader = async () => {
  const id_token = localStorage.getItem(ID_TOKEN);
  const expires_in = localStorage.getItem(EXPIRES_IN);
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  if (id_token) {
    if (Date.now() >= Number(expires_in)) {
        try {
            const newToken = await refreshToken(refresh_token);
            await localStorage.setItem(ID_TOKEN, newToken);
            return {
                Authorization: `Bearer ${newToken}`,
              }
        } catch (e) {
            return window.location.replace('/login');
        }
    } else {
        return {
            Authorization: `Bearer ${id_token}`,
          }
    }
}
else{
    return {}
}
};

const logout = () => {
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(EMAIL);
  localStorage.removeItem(EXPIRES_IN);
  localStorage.removeItem(REFRESH_TOKEN);
}

const getEmailFromToken = async () => {
  let email = localStorage.getItem(EMAIL);
  const authHeader = await authorizationHeader();
  if (!email) {
    const response = await fetch(
      authUrl('/google/token'),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader,
        }
      },
    );
    const body = await response.json();
    email = body.email;
    if (email) {
      localStorage.setItem(EMAIL, email);
    }
  }
  return email;
};

export {
  isAuthenticated,
  issueToken,
  authorizationHeader,
  logout,
  getEmailFromToken,
}
