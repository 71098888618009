import React, { useEffect, useState } from 'react'
import {
    Form,
    Button,
    Input,
    Upload,
    notification,
    Select,
    Checkbox
} from "antd";
import { UploadOutlined, FileZipOutlined, CheckOutlined } from '@ant-design/icons';
import { LOG_ENTRY_TYPES } from '../redux/reducers/uploadState';
import { useSelector } from 'react-redux';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 },
};
const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList
};

const beforeUpload = (file, type) => {
    console.log('file.type: ',file.type);
    console.log('type: ',type);
    console.log('-----------------');
    // eslint-disable-next-line
    const isValid = file.type === type || Array.isArray(type) && type.some(x => x === file.type);
    if (!isValid) {
        notification.error({
            message: 'Валидация не пройдена',
            description: `${file.name} не ${type} файл`
        });
    }
    return isValid ? true : Upload.LIST_IGNORE;
}

const customFormItemText = (name, rules, placeholder, type) => {
    return <Form.Item
        {...formItemLayout}
        key={name}
        name={name}
        rules={[rules]}
    >
        <Input placeholder={placeholder} />
    </Form.Item>;
}

const customFormItemCheckbox = (name, rules, placeholder, type) => {
    return <Form.Item
        {...formItemLayout}
        valuePropName="checked"
        key={name}
        name={name}
        rules={[rules]}
    >
        <Checkbox>{placeholder}</Checkbox>
    </Form.Item>;
}

const customFormItemSelect = (name, rules, placeholder, type, versions) => {
    return <Form.Item
        {...formItemLayout}
        key={name}
        name={name}
        rules={[rules]}
    >
        <Select placeholder="Версия">
            {versions && versions.length && versions
                .map(x => <Select.Option value={x.version}
                                         key={x.version}>{x.version}</Select.Option>)}
        </Select>
    </Form.Item>;
}

const customFormItemUpload = (name, rules, multiple, presetName, placeholder, typeValid, type) => {

    return <Form.Item
        {...formItemLayout}
        key={name}
        name={name}
        rules={[rules]}
        valuePropName="fileList[0].response.object.audio[0].location"
        getValueFromEvent={(e) => normFile(e)}
        hidden={!presetName}
    >
        <Upload
            name={name}
            multiple={multiple}
            action={`${backendURL}/upload/${presetName}`}
            beforeUpload={(file) => beforeUpload(file, typeValid)}
            showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
        >
            <h3 >{placeholder}</h3>
            <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
    </Form.Item>
}

const zipUploadFileName = (name) => `${name}.zip`;

const ZipUpload = (name, rules, multiple, maxCount, presetName, placeholder, typeValid, type, uploadLog, handleUploadRequest) => {
    const [uploadRequests, setUploadRequests] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const fileName = zipUploadFileName(name);
    const requirement = useSelector(state => state.upload.requirements.find(x => x.fileName === name));
    useEffect(() => {
        const queued = uploadLog.filter(x => x.inputName === name && x.type === LOG_ENTRY_TYPES.ARCHIVING);
        queued.forEach(x => {
            const uploadRequest = uploadRequests.find(ur => ur.file.name === x.fileName
                && !uploadLog.some(ul => ul.inputName === name
                    && ul.type === LOG_ENTRY_TYPES.UPLOADED
                    && ul.fileName === x.fileName));
            if (!uploadRequest)
                return;
            uploadRequest.onProgress({percent: 1}, uploadRequest.file);
        });
        const uploaded = uploadLog.filter(x => x.inputName === name && x.type === LOG_ENTRY_TYPES.UPLOADED);
        const archiveLog = uploaded.find(x => x.fileName === fileName);
        uploaded.forEach(x => {
            const uploadRequest = uploadRequests.find(ur => ur.file.name === x.fileName);
            if (!uploadRequest)
                return;
            uploadRequest.onProgress({percent: 100}, uploadRequest.file);
            uploadRequest.onSuccess(archiveLog.link);
        });
        if (requirement && requirement.requiredFilesCount === uploaded.length) {
            setIsFinished(true);
        }
    }, [uploadLog, fileName, name, requirement, uploadRequests])
    function customRequest(request) {
        handleUploadRequest(presetName, request);
        setUploadRequests([...uploadRequests, request]);
    }
    return <Form.Item
        {...formItemLayout}
        key={name}
        name={name}
        rules={[rules]}
        valuePropName="fileList[0].response.object.audio[0].location"
        getValueFromEvent={(e) => normFile(e)}
        hidden={!presetName}
    >
        <Upload
            name={name}
            multiple={multiple}
            maxCount={maxCount}
            customRequest={customRequest}
            beforeUpload={(file) => beforeUpload(file, typeValid)}
            showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
        >
            <h3 >{placeholder}</h3>
            {isFinished && <div><FileZipOutlined /> {fileName} <CheckOutlined /></div>}
            {!isFinished && <Button icon={<UploadOutlined />}>Загрузить</Button>}
        </Upload>

    </Form.Item>
}




const customFormItemUploadHelper = ({ type, name, multiple = false, rules, placeholder, typeValid, requiredFilesCount, versions }, presetName, uploadLog = null, handleUploadRequest = null) => {
    let item = null;
    // eslint-disable-next-line default-case
    switch (type) {
        case "text": item = customFormItemText(name, rules, placeholder, type); break;
        case "checkbox": item = customFormItemCheckbox(name, rules, placeholder, type); break;
        case "version": item = customFormItemSelect(name, rules, placeholder, type, versions); break;
        case "samples": item = ZipUpload(name, rules, multiple, requiredFilesCount, presetName, placeholder, typeValid, type, uploadLog, handleUploadRequest); break;
        case "audio":
        case "file":
        case "midi":
        case "video":
        case "image": item = customFormItemUpload(name, rules, multiple, presetName, placeholder, typeValid, type); break;
    }
    return item;
}
export { customFormItemUploadHelper, beforeUpload, normFile, ZipUpload, zipUploadFileName };
