import React, {useEffect} from 'react'
import { Row, Col } from "antd";
import { useHistory } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import RegisterForm from "../../components/RegisterForm";
import {
    createPreset
} from '../../redux/actions/presetsActions';
import midiFileToStepTutorialGroovepadLesson from '../../helpers/midiFileToStepTutorialGroovepadLesson'
import { createClips } from '../../helpers/clipHelpers'
import { addUploadRequest, uploadInit } from '../../redux/actions/uploadActions';
import { createRequirement } from '../../redux/reducers/uploadState';
import { fields } from '../../helpers/fieldList';

const CreatePreset = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const uploadLog = useSelector(state => state.upload.log);

    useEffect(() => {
        const samplesField = fields.find(x => x.type === 'samples');
        dispatch(uploadInit([
            createRequirement(samplesField.name, samplesField.requiredFilesCount)
        ]));
    }, [dispatch]);

    const handleSubmit = async values => {
        const samplesArchiveLink = values?.samples[0].response;
        const midiLinks = values?.midi.map(item => item.response);
        const midiPromises = values?.midi.map(async item => await midiFileToStepTutorialGroovepadLesson(item.originFileObj, values.bpm));
        const midi = await Promise.all(midiPromises);
        const clips = await createClips(values.padsConfig[0], midi);
        const body = {
            name: values.name,
            author: values.author,
            bpm: values.bpm,
            category: values.category,
            premium: !!values.premium,
            version: values.version,
            image: values?.image?.find(x => x.status === 'done')?.response,
            video: values?.video?.find(x => x.status === 'done')?.response,
            audio: values?.audioPreview?.find(x => x.status === 'done')?.response,
            file: values?.padsConfig?.find(x => x.status === 'done')?.response,
            midi: midiLinks,
            samples: samplesArchiveLink,
            clips: clips
        }
        dispatch(createPreset(body))
        history.push('/')
    };
    const handleUploadRequest = async (presetName, uploadRequest) => {
        dispatch(addUploadRequest(presetName, uploadRequest));
    }
    return <>
        <Row justify='center'>
            <h1>Создание пресета</h1>
        </Row>
        <Row justify='center'>
            <Col xl={5} md={8}>
                <RegisterForm handleSubmit={handleSubmit}
                              uploadLog={uploadLog}
                              handleUploadRequest={handleUploadRequest} />
            </Col>
        </Row>

    </>
}

export default CreatePreset;
