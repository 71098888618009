import {
    GET_PRESET_SUCCESS,
    ADD_PRESET_SUCCESS,
    UPDATE_PRESET_REQUESTED,
    COPY_PRESET_TO_TARGET_REQUESTED,
    COPY_PRESET_TO_ALL_REQUESTED,
} from './actionTypes';

export const getPreset = (data) => {
    return {
        type: GET_PRESET_SUCCESS,
        payload: data,
    };
};

export const setPreset = (data) => {
    return {
        type: ADD_PRESET_SUCCESS,
        payload: data,
    };
};

export const updatePreset = (id, data) => {
    return {
        type: UPDATE_PRESET_REQUESTED,
        payload: {
            id,
            data
        },
    }
};

export const copyPresetToTargetVersion = (id, targetVersion) => {
    return {
        type: COPY_PRESET_TO_TARGET_REQUESTED,
        payload: {
            id,
            targetVersion
        }
    }
};

export const copyPresetToAllVersions = (id) => {
    return {
        type: COPY_PRESET_TO_ALL_REQUESTED,
        payload: {
            id
        }
    }
};
