import {
    UPLOAD_FILE_FAILED,
    UPLOAD_FILE_REQUESTED,
    UPLOAD_FILE_SUCCESS, UPLOAD_INIT
} from '../actions/actionTypes';
import uploadState, { createLogEntry, LOG_ENTRY_TYPES } from './uploadState';

export default function uploadReducer(state = uploadState.initialState(), action) {
    switch (action.type) {
        case UPLOAD_INIT:
            const uploadInit = uploadState.initialState();
            return uploadState.addUploadRequirement(uploadInit, action.payload.requirements);
        case UPLOAD_FILE_REQUESTED:
            const uploadFileRequested = uploadState.addLogEntries(state, [
                createLogEntry(
                    action.payload.presetName,
                    action.payload.uploadRequest.filename,
                    action.payload.uploadRequest.file.name,
                    LOG_ENTRY_TYPES.ARCHIVING
                )]);
            return uploadState.addUploadRequest(uploadFileRequested, action.payload.uploadRequest);
        case UPLOAD_FILE_SUCCESS:
            return uploadState.addLogEntries(state, [
                createLogEntry(
                    action.payload.presetName,
                    action.payload.inputName,
                    action.payload.fileName,
                    LOG_ENTRY_TYPES.UPLOADED,
                    {link: action.payload.link}
                )]);
        case UPLOAD_FILE_FAILED:
            return uploadState.addLogEntries(state, [
                createLogEntry(
                    action.payload.presetName,
                    action.payload.uploadRequest.filename,
                    action.payload.uploadRequest.file.name,
                    LOG_ENTRY_TYPES.FAILED)]);
        default:
            return state;
    }
}
