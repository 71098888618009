import axios from 'axios';
import { backendUrl } from './url';

const getData = (response) => {
    return response
        .then((result) => result.data)
        .catch((error) => {
            throw error;
        });
};

const versionGet = async () =>
    await getData(axios.get(backendUrl('/version')))
const versionPost = async (data) =>
    await getData(axios.post(backendUrl('/version'), data, {
        'Content-Type': 'multipart/form-data'
    }));
const versionDelete = async (version) =>
    await getData(axios.delete(backendUrl(`/version/${version}`)));
const presetSetSortOrder = async (idsWithSortOrder) =>
    await getData(axios.post(backendUrl(`/preset/sort-order`), idsWithSortOrder));
const presetPatch = async (id, data) =>
    await getData(axios.patch(backendUrl(`/preset/${id}`), data));
const presetVersionDelete = async (id, version) =>
    await getData(axios.delete(backendUrl(`/preset/${id}/${version}`)));
const presetCopyToVersionPost = async (id, version) =>
    await getData(axios.post(backendUrl(`/preset/${id}/copy-to/${version}`)));
const presetCopyToAllVersionsPost = async (id) =>
    await getData(axios.post(backendUrl(`/preset/${id}/copy-to/all`)));

export {
    versionGet,
    versionPost,
    versionDelete,
    presetVersionDelete,
    presetPatch,
    presetSetSortOrder,
    presetCopyToVersionPost,
    presetCopyToAllVersionsPost
}

