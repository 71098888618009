import { Midi } from '@tonejs/midi';
import cutOffExtension from './cutOffExtension';

const midiToPadTable = {
    36: 1,
    37: 2,
    38: 3,
    39: 4,
    40: 5,
    41: 6,
    42: 7,
    43: 8,
};

function hasBadPadDescriptions(padsDict) {
    for (var key in padsDict) {
        var prevStart = -1;
        const touches = padsDict[key];
        for (const touch of touches) {
            if (prevStart === touch.start) {
                return true;
            }

            prevStart = touch.start;
        }
    }
}
const calculateBarsCount = (midi) => {
    return Math.ceil(midi.durationTicks / midi.header.ppq / 4);
}


function midiJsonToLesson(name, midi) {
    const tickDuration = 0.125;

    const padsDict = midi.tracks[0].notes.reduce((total, element) => {
        const padIndex = midiToPadTable[element.midi];
        if (!total[padIndex]) {
            total[padIndex] = [];
        }

        if (padIndex !== undefined) {
            total[padIndex].push({
                start: Math.trunc(element.time / tickDuration),
                duration: element.duration > tickDuration ? Math.trunc(element.duration / tickDuration) : undefined,
            });
        }
        return total;
    }, {});

    if (hasBadPadDescriptions(padsDict)) {
        throw new Error('Ошибка! Недопустимые описания падов в файле ' + midi.name);
    }

    const fullDuration = midi.tracks[0].notes.reduce((total, element) => {
        const elementEnd = element.time + element.duration;
        return total > elementEnd ? total : elementEnd;
    }, 0);

    const embientPads = midi.tracks[0].notes.reduce((total, element) => {
        if (element.velocity < 0.9) {
            const padIndex = midiToPadTable[element.midi];

            total.push(padIndex);
        }

        return total.filter((item, pos, self) => self.indexOf(item) === pos);
    }, []);

    const lessonDesc = {
        name,
        barsCount: calculateBarsCount(midi),
        sequencerSize: fullDuration / tickDuration + 1,
        pads: padsDict,
        embientPads: embientPads.length > 0 ? embientPads : undefined,
    };

    return lessonDesc;
}

async function readContent(file) {
    return new Promise(res => {
        const reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.readAsArrayBuffer(file);
    });
}

// eslint-disable-next-line max-params
export default async function midiFileToStepTutorialGroovepadLesson(file, bpm) {
    const fileContent = await readContent(file);
    const midiJson = new Midi(fileContent);
    const nameWithoutExtension = cutOffExtension(file.name);
    return midiJsonToLesson(nameWithoutExtension, midiJson, bpm);
}
