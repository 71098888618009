import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Row, Button, List, Modal, Select, Popconfirm, Badge } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import {
    deletePreset,
    deletePresetVersion,
    getPresets,
    sortPresets
} from '../../redux/actions/presetsActions';
import { setPreset } from '../../redux/actions/activePresetActions';
import PresetView from "../PresetView";
import EditForm from "../EditForm";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { getVersions } from '../../redux/actions/versionsActions';
const PresetList = () => {
    const presets = useSelector((state) => state.presets);
    const preset = useSelector((state) => state.activePreset);
    const versions = useSelector((state) => state.versions);
    const pageSize = 1000;
    const [page, setPage] = useState(1);
    const [visible, setVisible] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [version, setVersion] = useState('')
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVersions());
    }, [dispatch]);
    useEffect(() => {
        if (version) {
            dispatch(getPresets(version));
        }
    }, [version, dispatch]);
    useEffect(() => {
        if (versions.data && versions.data.length) {
            if (!version) {
                if (versions.data.find(x => x.version === 'dev')) {
                    setVersion('dev');
                } else {
                    setVersion(versions.data[0].version)
                }
            }
        }
    }, [version, versions, dispatch])

    const showModal = (statusVisible, activePreset) => {
        dispatch(setPreset(activePreset));
        setVisible(statusVisible)
        setIsEdit(false)
        if (!statusVisible) {
            dispatch(getPresets(version));
        }
    }
    const calcAbsoluteIndex = index => (page - 1) * pageSize + index;
    const onSortEnd = ({oldIndex, newIndex}) => {
        dispatch(sortPresets(calcAbsoluteIndex(oldIndex), calcAbsoluteIndex(newIndex)));
    };
    const onPageChange = (page, pageSize) => {
        setPage(page);
    }
    const ItemPicture = ({value}) => <img width={149} height={149}
                                          src={value.video ? 'images/file-play.svg' : value.image}
                                          alt={value.name}/>;
    const ItemPictureWithBadge = ({value}) => value.premium ?
        <Badge.Ribbon text='PREMIUM'><ItemPicture
            value={value}/></Badge.Ribbon> :
        <ItemPicture value={value}/>;
    const SortableItem = SortableElement(({value}) => (
        <List.Item key={value._id}>
            <List.Item.Meta
                onClick={() => showModal(true, value._id)}
                avatar={<ItemPictureWithBadge value={value}></ItemPictureWithBadge>}
                description={<div>
                    <h1>{value.name}</h1>
                    <p>Автор: {value.author}</p>
                    <p>#{value.category}</p>
                </div>}
            />
        </List.Item>
    ));
    const SortableList = SortableContainer(({items}) => (
        <List
            itemLayout="horizontal"
            dataSource={items}
            pagination={{
                current: page,
                pageSize,
                onChange: onPageChange
            }}
            renderItem={(item, index) => <SortableItem key={`item-`+item._id} index={index} value={item} />}
        />
    ));
    const activePreset = presets.data && presets.data.find(item => item._id === preset.data)
    const handleDelete = () => {
        dispatch(deletePreset(activePreset));
        setVisible(false);
    }
    const handleDeleteFromVersion = () => {
        dispatch(deletePresetVersion(activePreset._id, version));
        setVisible(false);
    }
    return <>
        <Select placeholder="Версия"
                value={version}
                style={{width: 100}}
                onChange={e => setVersion(e)}>
            {versions.data && versions.data.length && versions.data
                .map(x => <Select.Option value={x.version}
                                         key={x.version}>{x.version}</Select.Option>)}
        </Select>
        <SortableList items={presets.data}
                      pressDelay={200}
                      onSortEnd={onSortEnd} />
        <Modal
            title={activePreset && activePreset.name}
            visible={visible}
            onCancel={() => showModal(false, null)}
            footer={[
                <Row justify='center' key='footer'>
                    <Button type={'primary'} onClick={() => setIsEdit(!isEdit)}>{isEdit ? "Просмотр" : "Редактировать"}</Button>
                    <Popconfirm title={'Действительно удалить?'}
                                onConfirm={handleDelete}>
                        <Button type={'danger'} icon={<DeleteOutlined />}>Удалить</Button>
                    </Popconfirm>
                    <Popconfirm title={`Действительно удалить из версии ${version}?`}
                                onConfirm={handleDeleteFromVersion}>
                        <Button type={'dashed'} danger icon={<DeleteOutlined />}>
                            Удалить из версии <strong> {version}</strong></Button>
                    </Popconfirm>
                </Row>
            ]}
        >
            {
                !isEdit ? <PresetView {...activePreset} /> : <EditForm {...activePreset} />
            }

        </Modal>
    </>
}


export default PresetList;
