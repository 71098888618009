import { flatten } from 'lodash';

async function readFile(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function () {
            resolve(reader.result && reader.result.split('\n'))
        };
        reader.onerror = function () {
            console.log(reader.error);
        };
    });
}

const unionPadsAndClips = (title, newMidi) => {
    let arr = [];
    for (let i = 0; i < 8; i++) {
        const pads = newMidi.find(item => Number(item.track) === i + 1)?.pads;
        const triggers = flatten(Object.keys(pads || {})
            .filter(i => i !== 'undefined')
            .map(sampleIndex => pads[sampleIndex].map(trigger => ({[trigger['start']]: sampleIndex}))))
            .sort((a, b) => {
                const aTriggerIndex = Object.keys(a)[0];
                const bTriggerIndex = Object.keys(b)[0];
                return aTriggerIndex - bTriggerIndex;
            });
        arr[i] = {
            title: `${title?.replace('\r', '')} ${i + 1}`,
            length: newMidi.find(item => Number(item.track) === i + 1)?.barsCount,
            triggers: triggers
        }
    }
    return arr
}

const createClips = async (file, midi) => {
    const newMidi = midi.map(item => ({ track: item.name.split('_')[1], clip: item.name.split('_')[0], ...item }))
    const pads = await readFile(file.originFileObj);
    const clips = pads.filter(item => item.trim().length > 0).map((item, index) => {
        const targetMidis = newMidi.filter(item => Number(item.clip) === index + 1)
        return {
            [item.split(' ')[1]]: unionPadsAndClips(item.split(' ')[2], targetMidis)
        }
    })
    return clips
}


const editClips = async (midi, clips) => {
    const newMidi = midi.map(item => ({ track: item.name.split('_')[1], clip: item.name.split('_')[0], ...item }))
    const newClips = clips.map((item, index) => {
        const targetMidis = newMidi.filter(item => Number(item.clip) === index + 1)

        return {
            [Object.keys(item)[0]]: unionPadsAndClips(item[Object.keys(item)[0]][0].title.split(' ')[0], targetMidis)
        }
    })
    return newClips
}

export { createClips, editClips }
