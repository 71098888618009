import React from 'react'
import { Row, Col } from "antd";
import PresetList from "../../components/PresetList"

const Home = () => {
    return <div >
        <Row justify='center' >
            <h1>Список пресетов</h1>
        </Row>
        <Row justify='center'>
            <Col xl={12} md={12}>
                <PresetList />
            </Col>
        </Row>

    </div>
}

export default Home;
