import React from 'react';
import GoogleAuthLoginForm from '../../components/GoogleAuthLoginForm';

const Login = () => {
  return (
    <GoogleAuthLoginForm />
  );
}

export default Login;
