import { Col, Row } from 'antd';
import React from 'react';
import { VersionList } from '../../components/VersionList';

export const Versions = () => {
    return <div >
        <Row justify='center' >
            <h1>Список версий</h1>
        </Row>
        <Row justify='center'>
            <Col xl={12} md={12}>
                <VersionList />
            </Col>
        </Row>

    </div>
};
