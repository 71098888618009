import {
    GET_PRESETS_REQUESTED,
    ADD_PRESETS_REQUESTED,
    DELETE_PRESETS_REQUESTED,
    DELETE_PRESETS_VERSION_REQUESTED,
    SORT_PRESETS_REQUESTED,
} from './actionTypes';

export const getPresets = (version) => {
    return {
        type: GET_PRESETS_REQUESTED,
        payload: version,
    };
};

export const sortPresets = (oldIndex, newIndex) => {
    return {
        type: SORT_PRESETS_REQUESTED,
        payload: {
            oldIndex,
            newIndex
        }
    }
}

export const createPreset = (data) => {
    return {
        type: ADD_PRESETS_REQUESTED,
        payload: data,
    };
};

export const deletePreset = (data) => {
    return {
        type: DELETE_PRESETS_REQUESTED,
        payload: data,
    };
};

export const deletePresetVersion = (id, version) => {
    return {
        type: DELETE_PRESETS_VERSION_REQUESTED,
        payload: {
            id,
            version
        },
    };
};
