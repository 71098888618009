import React, { Fragment } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import { isAuthenticated } from './services/auth';
import Login from './pages/Login';
import Home from './pages/Home';
import CreatePreset from './pages/CreatePreset';
import OAuthCallback from './pages/OAuthCallback';
import { frontendPaths, frontendUrl } from './services/url';
import Menu from './components/Menu'
import { Layout } from 'antd'
import { CreateVersion } from './pages/CreateVersion';
import { Versions } from './pages/Versions';
import { Notifications } from './components/Notifications';
const { Header, Content } = Layout;

const PrivateRoute = ({ children, ...props }) => (
    <Route {...props}>
        {isAuthenticated() ? children : <Redirect to={frontendUrl('/login')} />}
    </Route>
);

function App() {

    return (
        <Router>
            <Switch>
                <Route path={frontendUrl('/login')}>
                    <Login />
                </Route>
                <Route path={frontendUrl('/callback')}>
                    <OAuthCallback />
                </Route>
                <Fragment>
                <Layout className="layout">
                    <Notifications />
                    <Header style={{  zIndex: 1, width: '100%' }}>
                        <Menu />
                    </Header>
                    <Content style={{ minHeight: '110vh', padding: '2vh 0px' }}>
                        <PrivateRoute exact path={frontendUrl(frontendPaths.Root)}>
                            <Home />
                        </PrivateRoute>
                        <PrivateRoute path={frontendUrl(frontendPaths.Preset)}>
                            <CreatePreset />
                        </PrivateRoute>
                        <PrivateRoute exact path={frontendUrl(frontendPaths.Versions)}>
                            <Versions />
                        </PrivateRoute>
                        <PrivateRoute exact path={frontendUrl(frontendPaths.VersionsCreate)}>
                            <CreateVersion />
                        </PrivateRoute>
                    </Content>
                </Layout>
                </Fragment>
            </Switch>
        </Router>
    );
}

export default App;
