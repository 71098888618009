import { UPLOAD_FILE_REQUESTED, UPLOAD_INIT } from './actionTypes';

export const uploadInit = (requirements = []) => {
    return {
        type: UPLOAD_INIT,
        payload: {
            requirements
        }
    };
};

export const addUploadRequest = (presetName, uploadRequest) => {
    return {
        type: UPLOAD_FILE_REQUESTED,
        payload: {
            presetName,
            uploadRequest
        },
    };
};
