import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { notification } from 'antd';

const errorHandlingEffect = state => () => {
    if (!state.success && state.error) {
        notification.error({
            message: 'Ошибка',
            description: `${state.error}
                ${state.reference}`
        });
    }
}

export const Notifications = () => {
    const versions = useSelector(state => state.versions);
    useEffect(() => errorHandlingEffect(versions), [versions]);

    const presets = useSelector(state => state.presets);
    useEffect(() => errorHandlingEffect(presets), [presets]);

    return <></>;
};
