import { call, put, takeEvery } from 'redux-saga/effects';
import {
    ADD_VERSIONS_REQUESTED,
    ADD_VERSIONS_SUCCESS,
    DELETE_VERSIONS_REQUESTED,
    DELETE_VERSIONS_SUCCESS,
    GET_VERSIONS_REQUESTED,
    GET_VERSIONS_SUCCESS,
    VERSIONS_FAILED
} from '../actions/actionTypes';
import { versionDelete, versionGet, versionPost } from '../../services/backend';
import { failedHttpRequest } from '../actions/commonActions';

function* getVersions(action) {
    try {
        const data = yield call(() => versionGet());
        yield put({ type: GET_VERSIONS_SUCCESS, payload: data });
    } catch (error) {
        yield put(failedHttpRequest(VERSIONS_FAILED, error));
    }
}

function* versionsGetSaga() {
    yield takeEvery(GET_VERSIONS_REQUESTED, getVersions);
}

function* postVersion(action) {
    try {
        yield call(() => versionPost(action.payload));
        yield put({ type: ADD_VERSIONS_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(VERSIONS_FAILED, error));
    }
}

function* versionsAddSaga() {
    yield takeEvery(ADD_VERSIONS_REQUESTED, postVersion);
}

function* deleteVersion(action) {
    try {
        yield call(() => versionDelete(action.payload));
        yield put({ type: DELETE_VERSIONS_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(VERSIONS_FAILED, error));
    }
}

function* versionsDeleteSaga() {
    yield takeEvery(DELETE_VERSIONS_REQUESTED, deleteVersion);
}

export {
    versionsDeleteSaga,
    versionsAddSaga,
    versionsGetSaga
}
