import React, { useEffect, useState } from 'react'
import { Form, Button, Input, Upload, Checkbox, Select } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons';
import { beforeUpload } from '../../helpers/customFormItemUpload'
import { fields } from '../../helpers/fieldList'
import midiFileToStepTutorialGroovepadLesson from '../../helpers/midiFileToStepTutorialGroovepadLesson'
import { editClips } from '../../helpers/clipHelpers'
import { ZipUpload } from '../../helpers/customFormItemUpload';
import { addUploadRequest, uploadInit } from '../../redux/actions/uploadActions';
import { createRequirement } from '../../redux/reducers/uploadState';
import { isEqual } from 'lodash';
import {
    copyPresetToAllVersions,
    copyPresetToTargetVersion,
    updatePreset
} from '../../redux/actions/activePresetActions';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const EditForm = (props) => {
    const versions = useSelector(state => state.versions);
    const [copyTargetVersion, setCopyTargetVersion] = useState('');
    const [nameField, setNameField] = useState(props.name);
    const [categoryField, setCategoryField] = useState(props.category);
    const [authorField, setAuthorField] = useState(props.author);
    const [bpmField, setBpmField] = useState(props.bpm);
    const [premiumField, setPremiumField] = useState(props.premium);
    const [imageField, setImageField] = useState(props.image);
    const [videoField, setVideoField] = useState(props.video);
    const [audioField, setAudioField] = useState(props.audio);
    const [fileField, setFileField] = useState(props.file);
    // eslint-disable-next-line
    const [sampleField, setSampleField] = useState(props.samples);
    const [midiField, setMidiField] = useState(props.midi);
    const [clips, setClips] = useState(props.clips);
    const uploadLog = useSelector(state => state.upload.log);
    const dispatch = useDispatch();
    const samplesFieldDefinition = fields.find(x => x.type === 'samples');
    useEffect(() => {
        dispatch(uploadInit([
            createRequirement(samplesFieldDefinition.name, samplesFieldDefinition.requiredFilesCount)
        ]))
    }, [dispatch, samplesFieldDefinition]);
    const normFile = async (e, type) => {
        if (Array.isArray(e)) {
            return e;
        }
        switch (type) {
            case 'image': e && e.fileList[0].response && setImageField(e && e.fileList[0].response); break;
            case 'video': e && e.fileList[0].response && setVideoField(e && e.fileList[0].response); break;
            case 'audioPreview': e && e.fileList[0].response && setAudioField(e && e.fileList[0].response); break;
            case 'padsConfig': e && e.fileList[0].response && setFileField(e && e.fileList[0].response); break;
            case 'samples':
                if (e && e.fileList) {
                    const samplesArchiveLinks = e.fileList[0].response;
                    setSampleField(samplesArchiveLinks)
                }; break;
            case 'midi':
                if (e && e.fileList) {
                    const midiPromises = e.fileList.map(async item => await midiFileToStepTutorialGroovepadLesson(item.originFileObj))
                    const midi = await Promise.all(midiPromises)
                    const newClips = await editClips(midi, clips)
                    const midiLinks = e.fileList.map(item => item.response)
                    setMidiField(midiLinks)
                    setClips(newClips)
                }; break;
            default: console.log('default')
        }
        return e && e.fileList
    };

    const handleSubmit = async values => {
        dispatch(updatePreset(props._id, values));
    };

    const handleUploadRequest = (presetName, uploadRequest) => {
        dispatch(addUploadRequest(presetName, uploadRequest));
    }

    const handleCopyToTargetVersion = (version) => {
        dispatch(copyPresetToTargetVersion(props._id, version));
    }

    const handleCopyToAllVersions = () => {
        dispatch(copyPresetToAllVersions(props._id));
    }

    return <Form
        name="editForm"
    >
        {
            fields && fields.length > 0 && fields.map(({ name, rules, placeholder, type, typeValid }) => {
                let item = null;
                // eslint-disable-next-line default-case
                switch (name) {
                    case "name": item = <div style={{ margin: 5 }}>
                        <Input disabled placeholder={placeholder} value={nameField} onChange={(e) => setNameField(e.target.value)} />
                        {
                            nameField !== props.name && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ name: nameField })} >Изменить</Button>
                        }
                    </div>; break;

                    case "premium": item = <div style={{ margin: 5}}>
                        <Checkbox checked={premiumField} onChange={(e) => setPremiumField(e.target.checked)}>Премиум</Checkbox>
                        {
                            premiumField !== props.premium && <Button size={'small'} type={'primary'} onClick={() => handleSubmit({ premium: premiumField })} >Изменить</Button>
                        }
                    </div>; break;

                    case "category": item = <div style={{ margin: 5 }}>
                        <Input placeholder={placeholder} value={categoryField} onChange={(e) => setCategoryField(e.target.value)} />
                        {
                            categoryField !== props.category && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ category: categoryField })} >Изменить</Button>
                        }
                    </div>; break;

                    case "author": item = <div style={{ margin: 5 }}>
                        <Input placeholder={placeholder} value={authorField} onChange={(e) => setAuthorField(e.target.value)} />
                        {
                            authorField !== props.author && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ author: authorField })} >Изменить</Button>
                        }
                    </div>; break;

                    case "bpm": item =
                        <div style={{ margin: 5 }}>
                            <Input placeholder={placeholder} value={bpmField} onChange={(e) => setBpmField(e.target.value)} />
                            {
                                bpmField !== props.bpm && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ bpm: bpmField })} >Изменить</Button>
                            }
                        </div>; break;

                    case "audioPreview": item =
                        <div style={{ margin: 10 }}>
                            <Upload
                                name={'audioPreview'}
                                action={`${backendURL}/upload/${nameField}`}
                                beforeUpload={(file) => beforeUpload(file, typeValid)}
                                onChange={(e) => normFile(e, name)}
                                showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                            >
                                <h3 >{placeholder}</h3>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            {
                                audioField !== props.audio && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ audio: audioField })} >Изменить</Button>
                            }
                        </div>
                        break;

                    case "padsConfig": item =
                        <div style={{ margin: 10 }}>
                            <Upload
                                name={'padsConfig'}
                                action={`${backendURL}/upload/${nameField}`}
                                beforeUpload={(file) => beforeUpload(file, typeValid)}
                                onChange={(e) => normFile(e, name)}
                                showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                            >
                                <h3 >{placeholder}</h3>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {
                                fileField !== props.file && < Button size={'small'} type={'primary'} onClick={() => handleSubmit({ file: fileField })} >Изменить</Button>
                            }
                        </div>
                        break;

                    case "midi": item =
                        <div style={{ margin: 10 }}>
                            <Upload
                                name={'midi'}
                                action={`${backendURL}/upload/${nameField}`}
                                beforeUpload={(file) => beforeUpload(file, typeValid)}
                                maxCount={64}
                                multiple
                                onChange={(e) => normFile(e, name)}
                                showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: false }}
                            >
                                <h3 >{placeholder}</h3>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {
                                !isEqual(clips, props.clips) && <><br />< Button size={'small'} type={'primary'} onClick={() => handleSubmit({ midi: midiField, clips: clips })} >Изменить</Button></>
                            }
                        </div>
                        break;

                    case "samples": item =
                        <div style={{ margin: 10 }}>
                            {ZipUpload(
                                samplesFieldDefinition.name,
                                samplesFieldDefinition.rules,
                                samplesFieldDefinition.multiple,
                                samplesFieldDefinition.requiredFilesCount,
                                nameField,
                                samplesFieldDefinition.placeholder,
                                samplesFieldDefinition.typeValid,
                                samplesFieldDefinition.type,
                                uploadLog,
                                handleUploadRequest
                            )}
                        </div>
                        break;

                    case "image": item =
                        <div style={{ margin: 10 }}>
                            <Upload
                                name="image"
                                action={`${backendURL}/upload/${nameField}`}
                                listType="picture"
                                onChange={(e) => normFile(e, name)}
                                beforeUpload={(file) => beforeUpload(file, typeValid)}
                            >
                                <h3 >{placeholder}</h3>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {
                                imageField !== props.image && <><br />< Button size={'small'} type={'primary'} onClick={() => handleSubmit({ image: imageField })} >Изменить</Button></>
                            }
                        </div>
                        break;

                    case "video": item =
                        <div style={{ margin: 10 }}>
                            <Upload
                                name="video"
                                action={`${backendURL}/upload/${nameField}`}
                                listType="video"
                                onChange={(e) => normFile(e, name)}
                                beforeUpload={(file) => beforeUpload(file, typeValid)}
                            >
                                <h3 >{placeholder}</h3>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>

                            </Upload>
                            {
                                videoField !== props.video && <><br />< Button size={'small'} type={'primary'} onClick={() => handleSubmit({ video: videoField })} >Изменить</Button></>
                            }
                        </div>
                        break;
                    default:
                        // skip field
                }
                return <div key={name}>{item}</div>
            }
            )
        }
        <hr/>
        <h3>Копирование пресета</h3>
        <div key="copyToVersion" style={{ margin: 10 }}>
            <Select placeholder="В версию"
                    style={{width: 120}}
                    onSelect={(value) => setCopyTargetVersion(value)}>
                {versions && versions.data && versions.data.length && versions.data
                    .map(x => <Select.Option value={x.version}
                                             key={x.version}>{x.version}</Select.Option>)}
            </Select>
            {!!copyTargetVersion &&
                <Button type="primary" onClick={() => handleCopyToTargetVersion(copyTargetVersion)}>
                    Копировать в версию <strong> {copyTargetVersion}</strong></Button>}
        </div>
        <div key="copyToAllVersions" style={{ margin: 10 }}>
            <Button type="primary" onClick={() => handleCopyToAllVersions()}>Копировать во все версии</Button>
        </div>
    </Form >
}


export default EditForm;
