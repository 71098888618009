import { call, put, takeEvery } from 'redux-saga/effects';
import {
    presetCopyToAllVersionsPost,
    presetCopyToVersionPost,
    presetPatch
} from '../../services/backend';
import {
    COPY_PRESET_TO_ALL_REQUESTED,
    COPY_PRESET_TO_TARGET_REQUESTED,
    COPY_PRESET_TO_TARGET_SUCCESS,
    PRESETS_FAILED,
    UPDATE_PRESET_REQUESTED,
    UPDATE_PRESET_SUCCESS,
} from '../actions/actionTypes';
import { failedHttpRequest } from '../actions/commonActions';

function* updatePreset(action) {
    try {
        yield call(() => presetPatch(action.payload.id, action.payload.data));
        yield put({ type: UPDATE_PRESET_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* activePresetSaga() {
    yield takeEvery(UPDATE_PRESET_REQUESTED, updatePreset);
}

function* copyToVersion(action) {
    try {
        yield call(() => presetCopyToVersionPost(action.payload.id, action.payload.targetVersion));
        yield put({ type: COPY_PRESET_TO_TARGET_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* activePresetCopyToVersionSaga() {
    yield takeEvery(COPY_PRESET_TO_TARGET_REQUESTED, copyToVersion);
}

function* copyToAllVersions(action) {
    try {
        yield call(() => presetCopyToAllVersionsPost(action.payload.id));
        yield put({ type: COPY_PRESET_TO_TARGET_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put(failedHttpRequest(PRESETS_FAILED, error));
    }
}

function* activePresetCopyToAllVersionsSaga() {
    yield takeEvery(COPY_PRESET_TO_ALL_REQUESTED, copyToAllVersions);
}

export {
    activePresetSaga,
    activePresetCopyToVersionSaga,
    activePresetCopyToAllVersionsSaga
}
