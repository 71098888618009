import React from 'react'
import { Menu } from 'antd'
import {
    Link,
    useHistory
} from 'react-router-dom';
import { frontendUrl } from '../../services/url';
import { logout } from '../../services/auth';

const CustomMenu = () => {
    const history = useHistory();
    const handleClick = () => {
        logout();
        history.push(frontendUrl('/login'));
    };
    return <Menu theme="dark" mode="horizontal" >
        <Menu.Item key={'preset_list'}> <Link to={'/'} > Список пресетов </Link></Menu.Item>
        <Menu.Item key={'preset_create'}> <Link to={'/preset'} > Создать пресет </Link></Menu.Item>
        <Menu.Item key={'version_list'}> <Link to={'/versions'} > Список версий </Link></Menu.Item>
        <Menu.Item key={'version_create'}> <Link to={'/versions/create'} > Создать версию </Link></Menu.Item>
        <Menu.Item style={{ marginLeft: '50vw' }} key={'logout'} onClick={handleClick}> Выйти </Menu.Item>
    </Menu>
}


export default CustomMenu;
