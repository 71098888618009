import React, { useEffect, useState } from 'react'
import { Form, Button } from "antd";
import { reduxForm } from 'redux-form'
import { customFormItemUploadHelper } from '../../helpers/customFormItemUpload'
import { fields as fieldsDefinitions } from '../../helpers/fieldList'
import { useDispatch, useSelector } from 'react-redux';
import { getVersions } from '../../redux/actions/versionsActions';
import { useForm } from 'antd/es/form/Form';


let RegisterForm = ({ handleSubmit, uploadLog, handleUploadRequest }) => {
    const versions = useSelector(state => state.versions);
    const dispatch = useDispatch();
    const [form] = useForm();
    const [disabled, setDisabled] = useState(true)
    const [presetName, setPresetName] = useState('')
    const onFieldsChange = (_, fields) => {
        const requiredFields = fields.filter(x => fieldsDefinitions.some(y => x.name.includes(y.name) && y.rules.required));
        const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
        setDisabled(hasErrors || requiredFields
            .map(({ value }) => value).includes(undefined) || requiredFields.map(({ value }) => value).includes(''))
        fields[0]?.value && setPresetName(fields[0].value)
    }
    useEffect(() => {
        dispatch(getVersions());
    }, [dispatch]);

    return <Form form={form}
        name="createForm"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        scrollToFirstError={true}
    >
        {
            fieldsDefinitions && fieldsDefinitions.length > 0 && fieldsDefinitions
                .map((field) => customFormItemUploadHelper({...field, versions: versions.data}, presetName, uploadLog, handleUploadRequest))
        }
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
                htmlType="submit"
                type="primary"
                disabled={disabled}
            >
                Сохранить
            </Button>
        </Form.Item>
    </Form >
}

RegisterForm = reduxForm({
    form: 'preset'
})(RegisterForm)


export default RegisterForm;
