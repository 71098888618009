import {
    ADD_VERSIONS_REQUESTED,
    ADD_VERSIONS_SUCCESS,
    DELETE_VERSIONS_REQUESTED,
    DELETE_VERSIONS_SUCCESS,
    GET_VERSIONS_REQUESTED,
    GET_VERSIONS_SUCCESS,
    VERSIONS_FAILED
} from '../actions/actionTypes';

const initialState = {
    data: [],
    loading: false,
    error: null,
    success: false
};

export function versionsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VERSIONS_REQUESTED:
            return { ...state, loading: true };
        case GET_VERSIONS_SUCCESS:
            return { ...state, success: true, loading: false, data: action.payload };
        case ADD_VERSIONS_REQUESTED:
        case DELETE_VERSIONS_REQUESTED:
            return { ...state, loading: true };
        case DELETE_VERSIONS_SUCCESS:
            return {
                ...state,
                success: true,
                data: state.data.filter(x => x._id !== action.payload),
            };
        case ADD_VERSIONS_SUCCESS:
            const addVersionsSuccessData = [action.payload, ...state.data];
            addVersionsSuccessData.sort();
            return {
                ...state,
                success: true,
                data: addVersionsSuccessData,
            };
        case VERSIONS_FAILED:
            return {
                ...state,
                success: false,
                error: action.message,
                reference: action.reference
            };
        default:
            return state;
    }
}
