import {
    GET_PRESET_REQUESTED,
    GET_PRESET_SUCCESS,
    ADD_PRESET_REQUESTED,
    ADD_PRESET_SUCCESS,
    COPY_PRESET_TO_TARGET_REQUESTED,
    COPY_PRESET_TO_TARGET_SUCCESS,
    COPY_PRESET_TO_ALL_REQUESTED,
    COPY_PRESET_TO_ALL_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    data: null,
    loading: false,
    error: null,
    success: false,
};

export default function activePresetReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRESET_REQUESTED:
            return { ...state, loading: true };
        case GET_PRESET_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        case ADD_PRESET_REQUESTED:
            return { ...state, loading: true };
        case ADD_PRESET_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.payload,
            };
        case COPY_PRESET_TO_TARGET_REQUESTED:
            return { ...state, loading: true };
        case COPY_PRESET_TO_TARGET_SUCCESS:
            return { ...state, loading: false };
        case COPY_PRESET_TO_ALL_REQUESTED:
            return { ...state, loading: true };
        case COPY_PRESET_TO_ALL_SUCCESS:
            return { ...state, loading: false };
        default:
            return state;
    }
}
