import { Button, Form, Input } from 'antd';

export const CreateVersionForm = ({ handleSubmit }) => {
    return <Form name="createVersionForm"
                 onFinish={handleSubmit}>
        <Form.Item label="Версия"
                   name="version"
                   rules={[{
                       required: true,
                       pattern: "[^\\s]+",
                       message: 'Необходимо ввести версию'
                   }]} >
            <Input />
        </Form.Item>
        <Form.Item >
            <Button type="primary" htmlType="submit">
                Сохранить
            </Button>
        </Form.Item>
    </Form>
};
