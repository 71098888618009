import axios from 'axios';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
    UPLOAD_FILE_FAILED, UPLOAD_FILE_QUEUED,
    UPLOAD_FILE_REQUESTED,
    UPLOAD_FILE_SUCCESS
} from '../actions/actionTypes';
import {backendUrl} from '../../services/url';
import {createZipBlobFromFiles} from '../../services/zip';
import {zipUploadFileName} from '../../helpers/customFormItemUpload';

const archiveFilesToZip = async (files, name, fileName) => {
    const content = await createZipBlobFromFiles(files);
    const formData = new FormData();
    formData.append(name, new File([content], fileName));
    return formData;
}

const postFileUpload = async (presetName, data) =>
    (await axios.post(backendUrl(`/upload/${presetName}`), data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })).data;


function* handleUploadRequest(action) {
    try {
        const fileName = action.payload.uploadRequest.filename;
        const upload = yield select(state => state.upload);
        const requirement = upload.requirements.find(x => x.fileName === fileName);
        if (!requirement) {
            throw new Error(`Upload requirement isn't registered ${fileName}`);
        }
        const requests = upload.requests.filter(x => x.filename === fileName);
        if (requests.length === requirement.requiredFilesCount) {
            const archiveName = zipUploadFileName(fileName);
            const formData = yield call(() => archiveFilesToZip(requests.map(x => x.file), fileName, archiveName));
            const link = yield call(() => postFileUpload(action.payload.presetName, formData));
            yield put({type: UPLOAD_FILE_SUCCESS, payload: {
                presetName: action.payload.presetName,
                inputName: fileName,
                fileName: archiveName,
                link
            }});
            for (const request of requests) {
                yield put({type: UPLOAD_FILE_SUCCESS, payload: {
                    presetName: action.payload.presetName,
                    inputName: fileName,
                    fileName: request.file.name
                }});
            }
        } else {
            yield put({type: UPLOAD_FILE_QUEUED, payload: action.payload});
        }
    } catch (error) {
        yield put({type: UPLOAD_FILE_FAILED, payload: {
            error: error.message,
            ...action.payload
        }});
    }
}

function* uploadSaga() {
    yield takeEvery(UPLOAD_FILE_REQUESTED, handleUploadRequest);
}

export { uploadSaga };
