export const failedHttpRequest = (actionType, error) => {
    const errorData = (error
        && error.response
        && error.response.data) || {
        message: error.message,
        reference: 'n/a'
    };
    return {
        type: actionType,
        message: errorData.error,
        reference: errorData.reference
    }
}
