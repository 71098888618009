const requireOnlyOneFieldRule = (dependentField, msg) =>
    ({ getFieldValue, getFieldError, validateFields }) => ({
    validator(_, value) {
        if (value || getFieldValue(dependentField)) {
            return Promise.resolve().then(() => {
                if (getFieldError(dependentField)) {
                    setTimeout(() => validateFields([dependentField]));
                }
            });
        }
        return Promise.reject(new Error(msg));
    },
})
const fields = [
    {
        name: 'name',
        rules: {
            required: true,
            message: 'Введите название пресета',
        },
        placeholder: 'Название',
        type: "text"
    },
    {
        name: 'author',
        rules: {
            required: true,
            message: 'Введите автора пресета',
        },
        placeholder: 'Автор',
        type: "text"
    },
    {
        name: 'bpm',
        rules: {
            required: true,
            message: 'Введите bpm',
        },
        placeholder: 'bpm',
        type: "text"
    },
    {
        name: 'category',
        rules: {
            required: true,
            message: 'Введите название категории',
        },
        placeholder: 'Категория',
        type: "text"
    },
    {
        name: 'premium',
        rules: {
            required: false
        },
        placeholder: 'Премиум',
        type: "checkbox"
    },
    {
        name: 'samples',
        rules: {
            required: true,
            message: 'Добавьте сэмплы для пресета',
        },
        requiredFilesCount: 64,
        placeholder: 'Сэмплы',
        type: "samples",
        typeValid: 'audio/wav',
        multiple: true
    },
    {
        name: 'midi',
        rules: {
            required: true,
            message: 'Добавьте midi файлы',
        },
        placeholder: 'MIDI файлы',
        type: "midi",
        typeValid: ['audio/midi', 'audio/mid'],
        multiple: true
    },
    {
        name: 'padsConfig',
        rules: {
            required: true,
            message: 'Добавьте pads конфиг',
        },
        placeholder: 'Pads конфиг',
        type: "file",
        typeValid: 'text/plain'
    },
    {
        name: 'audioPreview',
        rules: {
            required: true,
            message: 'Добавьте аудио превью',
        },
        placeholder: 'Аудио превью',
        type: "audio",
        typeValid: 'audio/mpeg'
    },
    {
        name: 'image',
        rules: requireOnlyOneFieldRule(
            "video", 'Добавьте обложку для пресета'),
        placeholder: 'Обложка',
        type: "image",
        typeValid: 'image/png'
    },
    {
        name: 'video',
        rules: requireOnlyOneFieldRule(
            "image", 'Добавьте видеообложку для пресета'),
        placeholder: 'Видео обложка',
        type: "video",
        typeValid: [
            'video/mp4',
            'video/mpeg'
        ]
    },
    {
        name: 'version',
        rules: {
            required: true,
            message: 'Выберите версию',
        },
        placeholder: 'Версия',
        type: "version",
    }
]

export { fields }
