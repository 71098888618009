import { all } from 'redux-saga/effects';
import {
    presetsSaga,
    presetCreateSaga,
    presetDeleteSaga,
    presetVersionDeleteSaga,
    presetsSortSaga
} from './presetsSaga';
import { uploadSaga } from './uploadSaga';
import {
    versionsAddSaga,
    versionsDeleteSaga,
    versionsGetSaga
} from './versionsSaga';
import {
    activePresetCopyToAllVersionsSaga,
    activePresetCopyToVersionSaga,
    activePresetSaga
} from './activePresetSaga';

export default function* rootSaga() {

    yield all([
        presetsSaga(),
        presetsSortSaga(),
        presetCreateSaga(),
        presetDeleteSaga(),
        presetVersionDeleteSaga(),
        activePresetSaga(),
        activePresetCopyToVersionSaga(),
        activePresetCopyToAllVersionsSaga(),
        uploadSaga(),
        versionsDeleteSaga(),
        versionsGetSaga(),
        versionsAddSaga()
    ]);

}
